<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> 
                        <span v-if="isReport == false">Danh sách đổ dầu</span>
                        <span v-else>Lịch sử đổ dầu</span>
                    </h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-if="isReport == false" v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="exportExcel()" @click="exportExcel()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                   <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="driverName" @md-selected="getDriverSelected" :md-options="drivers" @md-changed="getDrivers"  @md-opened="getDrivers">
                                    <label>Tất cả lái xe</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openDriver()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm lái xe</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="vehicleName" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles">
                                    <label>Tất cả phương tiện</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openVehicle()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm phương tiện</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                   </div>
                </div>
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:70px;">#</th>
                                <th class="left" style="width:15%;">Lái xe</th>
                                <th class="left" style="width:150px;">Số xe</th>
                                <th class="center" style="width:120px;">Ngày cấp</th>
                                <th style="width:150px;">Người cấp</th>
                                <th class="left" style="width:150px;">Đơn vị cấp</th>
                                <th class="right" style="width:120px;">Số lít</th>
                                <th class="right" style="width:120px;">Đơn giá</th>
                                <th class="center" style="width:120px;">VAT</th>
                                <th class="right" style="width:120px;">Thành tiền</th>
                                <th class="center" style="width:120px;">Trạng thái</th>
                                <th v-if="isReport == false" style="width:120px;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalItems > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="left">{{item.driver.fullName}}</td>
                                <td>{{item.vehicle.licensePlate}}</td>
                                <td class="center">{{item.createdDate}}</td>
                                <td>{{item.staff.fullName}}</td>
                                <td>{{item.company.companyAlias}}</td>
                                <td class="right">{{ formatNumber(item.quantity) }}</td>
                                <td class="right">{{ formatNumber(item.price) }}</td>
                                <td class="center">{{ formatNumber(item.vat) }}</td>
                                <td class="right">{{ formatNumber(item.amount) }}</td>
                                <td class="center">
                                    <md-chip v-if="item.isSubmited" class="md-primary">Đã chốt dầu</md-chip>
                                    <md-chip v-else>Chưa chốt dầu</md-chip>
                                </td>
                                <td v-if="isReport == false" class="action">
                                    <md-button @click="view(item.id)" class="md-fab md-mini md-view">
                                        <i class="uil uil-eye"></i>
                                        <md-tooltip>Xem</md-tooltip>
                                    </md-button>
                                    <md-button @click="edit(item)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    <md-button v-if="item.isSubmited == false" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                    <md-button v-else disabled class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                    <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>{{paging.page}}</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">{{paging.view}}:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <driverList ref="driverList" @close="closeDriver"/>
        <vehicleList ref="vehicleList" @close="closeVehicle"/>
        <crudForm ref="crudForm" @close="refresh()"/>
    </div>
</template>
<script>
    import crudForm from './Crud.vue';
    import fuelService from '../../../api/fuelService';
    import vehicleService from '../../../api/vehicleService';
    import driverService from '../../../api/driverService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import driverList from '../../../components/popup/_DriverList.vue';
    import vehicleList from '../../../components/popup/_VehicleList.vue';
    import { APP_CONFIG } from '@/utils/constants'

    export default {
        components: {
            crudForm,
            driverList,
            vehicleList,
        },
        metaInfo: {
            title: 'Danh sách đổ dầu'
        },
        data() {
            return {
                loadding: false,
                search: { pageIndex: 1, pageSize: common.pageSize, driverId: 0, vehicleId: 0, fromDate: this.getRangeDate(-7), toDate: '' },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                vehicleName: '',
                vehicles: [],
                driverName: '',
                drivers: [],
                isReport: false
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            if(this.$route.name === 'report-fuel'){
                this.isReport = true;
            }
            else{
                this.isReport = false;
            }
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/fuel/export-excel?driverId=" + this.search.driverId +
                "&vehicleId=" + this.search.vehicleId + "&fromDate=" + this.search.fromDate + "&toDate=" + this.search.toDate;
                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            btnSearch(){
                this.getData();
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search =  { pageIndex: 1, pageSize: common.pageSize, driverId: 0, vehicleId: 0, fromDate: this.getRangeDate(-7), toDate: '' };
                this.getData();
            },

            filter(){
                this.getData();
            },

            getData(){
                this.loadding = true;
                this.search.type = 1;
                fuelService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                fuelService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            edit(obj){
                this.$refs.crudForm.open(obj.id);
            },

            add(){
                this.$refs.crudForm.open(0);
            },

            //Vehicle
            closeVehicle(item){
                this.vehicleName = item.licensePlate;
                this.search.vehicleId = item.id;
                this.$refs.vehicleList.close();
            },

            getVehicleSelected(val){
                this.search.vehicleId = val.id;
                this.vehicleName = val.licensePlate;
            },

            openVehicle(){
                this.$refs.vehicleList.open();
            },

            getVehicles(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, licensePlate: searchTerm };
                vehicleService.getVehicles(search).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicles = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Driver
            closeDriver(item){
                this.driverName = item.fullName;
                this.search.driverId = item.id;
                this.$refs.driverList.close();
            },

            getDriverSelected(val){
                this.search.driverId = val.id;
                this.driverName = val.fullName;
            },

            openDriver(){
                this.$refs.driverList.open();
            },

            getDrivers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name: searchTerm };
                driverService.getDrivers(search).then((response) => {
                    if(response.statusCode == 200){
                        this.drivers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            view(id){
                this.$router.push('/fuel/view/' + id);
            }
        },
        watch: {
            '$route.name': function (){
                if(this.$route.name === 'report-fuel'){
                    this.isReport = true;
                }
                else{
                    this.isReport = false;
                }
            },
            driverName: function (val) { 
                if(val == ''){
                    this.search.driverId = 0;
                }
            },
            vehicleName: function (val) { 
                if(val == ''){
                    this.search.vehicleId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            }
        }
    }

</script>
