<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="driverName" @md-selected="getDriverSelected" :md-options="drivers" @md-changed="getDrivers"  @md-opened="getDrivers" :class="{'md-invalid': submitted && $v.entity.driverId.$error }">
                                    <label>Lái xe<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.driverId.isSelected">Vui lòng chọn lái xe</span>
                                </md-autocomplete>
                                <md-button @click="openDriver()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm lái xe</md-tooltip>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="licensePlate" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles" :class="{'md-invalid': submitted && $v.entity.vehicleId.$error }">
                                    <label>Số xe<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.vehicleId.isSelected">Vui lòng chọn xe</span>
                                </md-autocomplete>
                                <md-button @click="openVehicle()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm xe</md-tooltip>
                                </md-button>
                            </div>
                            <md-field>
                               <label for="budgetFuel">Định mức dầu(lít)</label>
                               <md-input disabled name="budgetFuel" v-model="budgetFuel" ></md-input>
                            </md-field>
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Người lập phiếu<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="companyName" @md-selected="getCompanySelected" :md-options="companies" @md-changed="getCompanies"  @md-opened="getCompanies" :class="{'md-invalid': submitted && $v.entity.companyId.$error }">
                                    <label>Đơn vị cấp dầu</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.companyId.isSelected">Vui lòng chọn đơn vị cấp dầu</span>
                                </md-autocomplete>
                                <md-button @click="openCompany()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.createdDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.createdDate.$error }">
                                <label>Ngày cấp dầu</label>
                                <span class="md-error" v-if="submitted && !$v.entity.createdDate.required">Vui lòng chọn ngày cấp dầu</span>
                            </md-datepicker>
                            <md-datepicker v-model="entity.actualDate" md-immediately :md-model-type="String">
                                <label>Ngày đổ dầu thực tế</label>
                            </md-datepicker>
                            <md-field>
                               <label for="fuelType">Loại dầu</label>
                               <md-input name="fuelType" v-model="entity.fuelType" ></md-input>
                            </md-field>
                            <md-field>
                               <label for="fuelAddress">Vị trí cây dầu</label>
                               <md-input name="fuelAddress" v-model="entity.fuelAddress" ></md-input>
                            </md-field>
                            <md-field>
                               <label for="note">Ghi chú</label>
                               <md-input name="note" v-model="entity.note" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field style="padding-top: 0; flex-direction: column; align-items: left;" :class="{'md-invalid': submitted && $v.entity.quantity.$error }">
                                <div>Số lít:</div>
                                <number @change="calSum()" name="quantity" v-model="entity.quantity" v-bind="number"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.quantity.required">Vui lòng nhập số lít</span>
                            </md-field>
                            <md-field style="padding-top: 0; flex-direction: column; align-items: left;" :class="{'md-invalid': submitted && $v.entity.price.$error }">
                                <div>Đơn giá(bao gồm VAT):</div>
                                <number @change="calSum()" name="price" v-model="entity.price" v-bind="number"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.price.required">Vui lòng nhập đơn giá</span>
                            </md-field>
                            <md-field style="padding-top: 0; flex-direction: column; align-items: left;" :class="{'md-invalid': submitted && $v.entity.vat.$error }">
                                <div>Vat:</div>
                                <number name="vat" v-model="entity.vat" v-bind="number"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.vat.required">Vui lòng nhập vat</span>
                            </md-field>
                            <md-field style="padding-top: 0; flex-direction: column; align-items: left;" :class="{'md-invalid': submitted && $v.entity.amount.$error }">
                                <div>Thành tiền:</div>
                                <number :disabled="true" name="amount" v-model="entity.amount" v-bind="number"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.amount.required">Vui lòng nhập thành tiền</span>
                            </md-field>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-if="entity.isSubmited == false" v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <staffList ref="staffList" @close="closeStaff"/>
        <driverList ref="driverList" @close="closeDriver"/>
        <vehicleList ref="vehicleList" @close="closeVehicle"/>
        <companyList ref="companyFuelList" title="Đơn vị đổ dầu" :type="companyType" @close="closeCompany"/>
    </md-dialog>
</template>
<script>
    import fuelService from '../../../api/fuelService';
    import staffService from "../../../api/staffService"
    import driverService from '../../../api/driverService';
    import vehicleService from '../../../api/vehicleService';
    import companyService from '../../../api/companyService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import staffList from '../../../components/popup/_StaffList.vue';
    import driverList from '../../../components/popup/_DriverList.vue';
    import vehicleList from '../../../components/popup/_VehicleList.vue';
    import companyList from '../../../components/popup/_CompanyList.vue';

    export default ({
        components: {
            driverList,
            vehicleList,
            staffList,
            companyList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật đổ dầu'
        },
        data() {
            return {
                showDialog: false,
                title: '',
                id: 0,
                submitted: false,
                entity: { },
                saveAndCreate: false,
                driverName: '',
                drivers: [],
                licensePlate: '',
                vehicles: [],
                companyName: '',
                companies: [],
                staffName: '',
                staffs: [],
                companyType: 13,
                number: common.currencyF2,
                budgetFuel: 0
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSum(){
                this.entity.amount = parseFloat(this.entity.price) * parseFloat(this.entity.quantity);
            },

            async open(id){
                this.id = id;
                this.licensePlate = "";
                this.driverName = "";
                this.companyName = "";
                if(id == 0){
                    this.title = 'Thêm mới cấp dầu';
                    this.entity = { 
                        id: 0, driverId: 0, vehicleId: 0, staffId: 0, companyId: 0,
                        createdDate: common.dateNow, actualDate: '', fuelAddress: '', fuelType: '',
                        quantity: 0, price: 0, vat: 0, amount: 0, note: '', isSubmited: false
                    };
                    this.getByAccount();
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật cấp dầu';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            //Company
            closeCompany(item){
                this.companyName = item.companyAlias;
                this.entity.companyId = item.id;
                this.$refs.companyFuelList.close();
            },

            getCompanySelected(val){
                this.entity.companyId = val.id;
                this.companyName = val.companyAlias;
            },

            openCompany(){
                this.$refs.companyFuelList.open(13);
            },

            getCompanies(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.companyType, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.companies = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Vehicle
            closeVehicle(item){
                this.licensePlate = item.licensePlate;
                this.entity.vehicleId = item.id;
                this.getBudgetFuel();
                this.$refs.vehicleList.close();
            },

            getVehicleSelected(val){
                this.entity.vehicleId = val.id;
                this.licensePlate = val.licensePlate;
                this.getBudgetFuel();
            },

            openVehicle(){
                this.$refs.vehicleList.open();
            },

            getVehicles(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, licensePlate:  searchTerm };
                vehicleService.getVehicles(search).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicles = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Driver
            closeDriver(item){
                this.driverName = item.fullName;
                this.entity.driverId = item.id;
                this.getBudgetFuel();
                this.$refs.driverList.close();
            },

            getDriverSelected(val){
                this.entity.driverId = val.id;
                this.driverName = val.fullName;
                this.getBudgetFuel();
            },

            openDriver(){
                this.$refs.driverList.open();
            },

            getDrivers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                driverService.getDrivers(search).then((response) => {
                    if(response.statusCode == 200){
                        this.drivers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                fuelService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('close');
                        if(this.saveAndCreate == false){
                            this.showDialog = false;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                fuelService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        this.$emit('close');
                        messageBox.showMessage("Cập nhật thành công");
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                fuelService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.driverName = response.data.driver.fullName;
                        this.licensePlate = response.data.vehicle.licensePlate;
                        this.companyName = response.data.company.companyAlias;
                        this.staffName = response.data.staff.fullName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getBudgetFuel(){
                this.budgetFuel = 0;
                if(this.entity.driverId > 0 && this.entity.vehicleId > 0){
                    fuelService.getBudgetFuel(this.entity.driverId, this.entity.vehicleId).then((response) => {
                        if(response.statusCode == 200){
                            this.budgetFuel = response.data;
                        }
                        else{
                            messageBox.showWarning(response.data);
                        }
                    }).finally(() => { this.setLoading(false); });
                }
            },

            back(){
                this.$router.push('/fuel');
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId;
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: { 
            driverName: function (val) { 
                if(val == ''){
                    this.entity.driverId = 0;
                    this.budgetFuel = 0;
                }
            },
            licensePlate: function (val) { 
                if(val == ''){
                    this.entity.vehicleId = 0;
                    this.budgetFuel = 0;
                }
            },
            companyName: function (val) { 
                if(val == ''){
                    this.entity.companyId = 0;
                }
            }
        },
        validations: {
            entity: {
                driverId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                vehicleId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                companyId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                createdDate: { required },
                quantity: { required },
                price: { required },
                vat: { required },
                amount: { required },
            }
       }
     })

</script>
<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 1000px;
        height: 500px;
        max-width: 1000px;
    }
    .md-scrollbar {
        height: 480px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>